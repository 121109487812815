<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-skeleton-loader class="ma-3 pa-2" v-if="loading" v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
        <div v-else-if="!(Array.isArray(company_list) && company_list.length)">
            <v-alert type="error">
                {{ error_msg }}
            </v-alert>
        </div>
        <div v-else>
            <v-row>
                <v-col v-for="(item, index) in company_list" :key="index" cols="12" sm="3" md="3">
                    <v-card>
                        <v-card-title class="green white--text">
                            {{ item.Company_name }}
                        </v-card-title>
                        <v-card-subtitle class="green white--text">

                            {{ item.placementtype }}
                        </v-card-subtitle>
                        <v-card-body>
                            <hr />
                            <div class="ma-2   grey--text text--lighten-1">
                                <div class="ma-1">
                                    <v-icon>mdi-rename-box</v-icon>
                                    {{ item.companytype }}<strong v-if="item.placementtype == 'Internship'">- {{
                                        item.internshiptype }}</strong>
                                </div>

                                <div class="ma-1">
                                    <v-icon left>mdi-timer</v-icon>{{ item.regEnddate }} {{ item.regEndtime }}
                                </div>
                            </div>
                        </v-card-body>
                        <v-card-actions>
                            <v-row justify="end" class="ma-2" align="start">
                                <v-btn dense color="primary" @click="process(item.id)" v-model="details_id">Apply</v-btn>
                                <v-btn color="grey" @click="finddetails(item)" v-model="details_id" dense>
                                    More
                                </v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

            <!-- round dialog -->

            <div class="text-center">
                <v-dialog v-model="dialog1" width="auto">
                    <v-card>
                        <v-card-title class="c-title">
                            <v-row justify="space-between">
                                Student Company Round Details
                                <v-btn icon dark @click="dialog1 = false">
                                    <v-icon color="black">mdi-close</v-icon>
                                </v-btn>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-row justify="center">
                                <template v-if="company != null">
                                    <v-row>
                                        <v-col md="6" sm="3">
                                            <span class="lbl"> Company Name </span>
                                        </v-col>
                                        <v-col md="6" sm="3">
                                            :{{ company.name }}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col md="6" sm="3">
                                            <span class="lbl"> Domain </span>
                                        </v-col>
                                        <v-col md="6" sm="3">
                                            : <span v-for="(i, index) in company.industry" :key=index> {{ i }}<br></span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col md="3" sm="3">
                                            <span class="lbl"> Offering</span>
                                        </v-col>
                                        <v-col md="4" sm="4">
                                            : {{ company.placementtype }}
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm='3' v-for="item in studentround_list" :key="item">
                                    <v-card>
                                        <v-card-title class="c-title">
                                            Round {{ item.round_number }} : {{ item.round_details }}
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="6" sm="4">
                                                    <span class="lbl"> Result</span>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    : <span v-if="item.round_isfinal == 'Pass'"
                                                        style="color:green;font-weight:bold;">
                                                        {{ item.round_student_status }}
                                                    </span>
                                                    <span v-else style="color:red;font-weight:bold;">
                                                        {{ item.round_student_status }}
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-row justify="center">
                                    <div style="margin-top:40px;margin-bottom:10px"
                                        v-if="this.finalselected == 'finalselected'">
                                        <v-card>
                                            <v-card-title class="c-title">
                                                You Are Selected
                                            </v-card-title>
                                        </v-card>
                                    </div>
                                </v-row>
                                <br>
                            </v-row>
                            <v-row justify="center">
                                <v-btn class="logi" outlined text @click="dialog1 = false">Close</v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>

            <template>
                <v-row justify="center">
                    <v-dialog v-model="dialog_apply" persistent max-width="800">
                        <v-card>
                            <v-card-title class="card-title">Apply to company<v-spacer></v-spacer> <v-icon
                                    class="mr-2  x-large" @click="close()"
                                    color="error">mdi-close-circle-outline</v-icon></v-card-title>
                            <input type="hidden" v-model="dataItem" />
                            <v-spacer></v-spacer>

                            <span v-for="(i, index) in offering_questions_list" :key=index>
                                <v-row max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto">
                                        <strong> Q{{ index + 1 }} :</strong> {{ i.question }}
                                    </v-col>
                                </v-row>
                                <v-row
                                    v-if="i.question_multichoice != true && i.question_choice != true && i.is_attachmentrequired != true && i.is_attachment_with_description != true"
                                    max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto">
                                        <v-text-field v-model="i.descrAnswer" placeholder="Enter Your Answer" clearable
                                            required>
                                        </v-text-field>
                                    </v-col>

                                </v-row>
                                <v-row v-else-if="i.is_attachmentrequired == true" max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px"><br />
                                        <v-row>
                                            <v-file-input type="file" placeholder="Select Attachment"
                                                prepend-icon="mdi-file" ref="file" show-size required="true" clearable
                                                v-model="i.answer_file"></v-file-input>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row v-else-if="i.is_attachment_with_description == true" max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto">
                                        <v-text-field v-model="i.descrAnswer" style="margin-top:-5px"
                                            placeholder="Enter Your Answer" clearable required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-50px">
                                        <v-file-input type="file" style="margin-top:30px;" placeholder="Select Attachment"
                                            prepend-icon="mdi-file" ref="file" show-size required="true" clearable
                                            v-model="i.answer_file"></v-file-input>
                                    </v-col>
                                </v-row>
                                <v-row v-else-if="i.question_multichoice == true" max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                                        <v-row>
                                            <v-col cols="3" sm="3" v-for="(role, index1) in i.optionlist" :key="index1">
                                                <v-checkbox :label="role.answer" color="success"
                                                    v-model="role.isSelectedOP"></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                                <v-row v-else-if="i.question_choice == true" max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                                        <v-row>
                                            <v-col cols="10" sm="9">
                                                <v-radio-group row v-model="i.singleanswer">
                                                    <v-radio v-for="n in i.optionlist" :key="n" :label="`${n.answer}`"
                                                        :value="n"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                            </span>
                            <v-row max-width="555">
                                <v-col cols="10" sm="10" class="mx-auto">
                                    <label class="lbl">Your CVs<span class="required">*</span> :</label>
                                    <v-select :items="cvList" v-model="cvFile" item-text="filename" item-value="id"
                                        :rules="[v => !!v || 'required']"></v-select>
                                </v-col>
                            </v-row>

                            <v-card-actions class="d-flex-center ma-auto">
                                <v-spacer></v-spacer>
                                <v-row>
                                    <!-- <v-btn elevation="2" raised class="logi" text @click="close()">Cancel</v-btn> -->
                                    <v-btn elevation="2" raised class="logi" text
                                        @click="applyCompany(offering_questions_list, index)">Submit</v-btn>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <template>
                <v-row justify="center">
                    <v-dialog v-model="question_dialog" persistent max-width="800">
                        <v-card>
                            <v-card-title class="card-title">Apply to company<v-spacer></v-spacer> <v-icon
                                    class="mr-2  x-large" @click="close()" color="error">mdi-close-circle-outline</v-icon>
                            </v-card-title>
                            <input type="hidden" v-model="dataItem" />
                            <v-spacer></v-spacer>


                            <span v-for="(i, index) in offering_questions_list" :key=index>
                                <v-row max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto">
                                        <strong> Q{{ index + 1 }} :</strong> {{ i.question }}
                                    </v-col>
                                </v-row>
                                <v-row
                                    v-if="i.question_multichoice != true && i.question_choice != true && i.is_attachmentrequired != true && i.is_attachment_with_description != true"
                                    max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto">
                                        <v-text-field v-model="i.descrAnswer" placeholder="Enter Your Answer" clearable
                                            required>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-else-if="i.is_attachment_with_description == true" max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto">
                                        <v-text-field v-model="i.descrAnswer" style="margin-top:-5px"
                                            placeholder="Enter Your Answer" clearable required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-50px">
                                        <v-file-input type="file" style="margin-top:30px;" placeholder="Select Attachment"
                                            prepend-icon="mdi-file" ref="file" show-size required="true" clearable
                                            v-model="i.answer_file"></v-file-input>
                                    </v-col>
                                </v-row>
                                <v-row v-else-if="i.is_attachmentrequired == true" max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                                        <v-row><br />
                                            <v-file-input type="file" placeholder="Select Attachment"
                                                prepend-icon="mdi-file" ref="file" show-size required="true" clearable
                                                v-model="i.answer_file"></v-file-input>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row v-else-if="i.question_multichoice == true" max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                                        <v-row>
                                            <v-col cols="3" sm="3" v-for="(role, index) in i.optionlist" :key="index">
                                                <v-checkbox :label="role.answer" color="success"
                                                    v-model="role.isSelectedOP"></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row v-else-if="i.question_choice == true" max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                                        <v-row>
                                            <v-col cols="10" sm="9">
                                                <v-radio-group row v-model="i.singleanswer">
                                                    <v-radio v-for="n in i.optionlist" :key="n" :label="` ${n.answer}`"
                                                        :value="n"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>


                            </span>
                            <v-card-actions class="d-flex-center ma-auto">
                                <v-spacer></v-spacer>
                                <v-row>
                                    <!-- <v-btn elevation="2" raised class="logi" text @click="closequestion()">cancel</v-btn> -->
                                    <v-btn elevation="2" raised class="logi" text
                                        @click="applyWOcvWques(offering_questions_list)">Submit</v-btn>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>



            <template>
                <v-row justify="center">
                    <v-dialog v-model="newquestion_dialog" persistent max-width="600">
                        <v-card>
                            <v-card-title class="card-title">Answer Questions<v-spacer></v-spacer> <v-icon
                                    class="mr-2  x-large" @click="newquestion_dialog = false"
                                    color="error">mdi-close-circle-outline</v-icon> </v-card-title>
                            <input type="hidden" v-model="dataItem" />
                            <v-spacer></v-spacer>


                            <span v-for="(i, index) in offeringnew_questions_list" :key=index>
                                <v-row max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto">
                                        <strong> Q{{ index + 1 }} :</strong> {{ i.question }}
                                    </v-col>
                                </v-row>
                                <v-row
                                    v-if="i.question_multichoice != true && i.question_choice != true && i.is_attachmentrequired != true && i.is_attachment_with_description != true"
                                    max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto">
                                        <v-text-field v-model="i.descrAnswer" placeholder="Enter Your Answer" clearable
                                            required>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-else-if="i.is_attachment_with_description == true" max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto">
                                        <v-text-field v-model="i.descrAnswer" style="margin-top:-5px"
                                            placeholder="Enter Your Answer" clearable required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-50px">
                                        <v-file-input type="file" style="margin-top:30px;" placeholder="Select Attachment"
                                            prepend-icon="mdi-file" ref="file" show-size required="true" clearable
                                            v-model="i.answer_file"></v-file-input>
                                    </v-col>
                                </v-row>
                                <v-row v-else-if="i.is_attachmentrequired == true" max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                                        <v-row><br />
                                            <v-file-input type="file" style="margin-top:30px;"
                                                placeholder="Select Attachment" prepend-icon="mdi-file" ref="file" show-size
                                                required="true" clearable v-model="i.answer_file"></v-file-input>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row v-else-if="i.question_multichoice == true" max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                                        <v-row>
                                            <v-col cols="3" sm="3" v-for="(role, index) in i.optionlist" :key="index">
                                                <v-checkbox :label="role.answer" color="success"
                                                    v-model="role.isSelectedOP"></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row v-else-if="i.question_choice == true" max-width="555">
                                    <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                                        <v-row>
                                            <v-col cols="10" sm="9">
                                                <v-radio-group row v-model="i.singleanswer">
                                                    <v-radio v-for="n in i.optionlist" :key="n" :label="` ${n.answer}`"
                                                        :value="n"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>


                            </span>
                            <v-card-actions class="d-flex-center ma-auto">
                                <v-spacer></v-spacer>
                                <v-row>
                                    <!-- <v-btn elevation="2" raised class="logi" text @click="closequestion()">cancel</v-btn> -->
                                    <v-btn elevation="2" raised class="logi" text
                                        @click="savenewQuestions(offeringnew_questions_list)">Submit</v-btn>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>



            <template>
                <v-row justify="center">
                    <v-dialog v-model="process_dialog" persistent max-width="600">
                        <v-card>
                            <v-card-title class="card-title" style="letter-spacing:1px;">{{ c_name }} :
                                <v-spacer></v-spacer><span>You Do not Fulfill Following Criteria to
                                    Apply</span></v-card-title>
                            <v-card-body><br>
                                <span style="margin-left:50px;color:red;font-size:20px;"
                                    v-for="(i, index) in mismatcharraynew" :key=index> {{ i }}<br><br></span>
                            </v-card-body>
                            <v-card-actions class="d-flex-center ma-auto">
                                <v-spacer></v-spacer>
                                <v-row>
                                    <v-btn elevation="2" raised class="logi" text
                                        @click="process_dialog = false">Close</v-btn>
                                    <!-- <v-btn elevation="2" raised class="logi" text @click="applyWithCV(item)">Submit</v-btn> -->
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>

        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            loading: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            company_list: [],
            error_msg: "",


            cvList: [],
            mismatcharray: [],
            mismatcharraynew: [],
            cvFile: null,
            process_dialog: false,

            dataItem: null,
            question_dialog: false,
            newquestion_dialog: false,
            offering_questions: [],
            c_name: "",
            dialog_apply: false,
            dialog_upload: false,
            dialog: false,
            dialog1: false,
            loadernew: false,
            companyregistration_list: null,
            companyregistrationclosed_list: null,
            companyregistrationclosedwithapplied_list: null,
            companyselected_list: null,
            companyprocesscompleted_list: null,
            details_id: "",
            question: null,
            singleanswerquestioncount: 0,
            ispersonaldetailsfreezed: false,
            isexaminationdetailsfreezed: false,
            isacademicsdetailsfreezed: false,
            offeringnew_questions_list: [],
            offering_questions_list: [],
            ismarkszeromsg: "",
            ismarkszero: false,
            file: '',
            company: null,
            savedata: {
                file: null,
                company_name: '',
            },
            studentround_list: [],
            finalselected: "",


            defaultItem: {
                id: "",
            },


        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loading = true;
            axios
                .post("/TPOCompanyScheduling/apply_company")
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.company_list = res.data.company_list;
                        this.loading = false;
                    } else {
                        this.loading = false;
                        this.error_msg = res.data.msg;


                    }
                })
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },

        handleFileUpload() {
            // alert("in handle = "+file)
            this.file = this.$refs.file;
        },


        submitFile() {
            //  alert("in submit : "+this.file);
            let formData = new FormData();
            formData.append('file', this.file);
            axios
                .post("/TPOCompanyScheduling/saveOfferletter", formData)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.msg == "200") {

                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                })
                .finally(() => { });

        },


        save(item) {

            if (this.savedata.file == null) {
                this.showSnackbar("#b71c1c", "Please Select File...");
            }
            else {
                let formData = new FormData();
                formData.append("file", this.savedata.file);
                formData.append("shortname", item.short_name);
                // formData.append("company_name", this.savedata.company_name);
                // window.alert(JSON.stringify(formData))
                axios
                    .post("/TPOCompanyScheduling/saveOfferletter", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((res) => {
                        if (res.data.msg == "200") {

                            this.showSnackbar("#4caf50", "File Addded Successfully...");
                            // document.getElementById("AddFile").style.display = "block";
                            //  this.onLoad(); // show snackbar on success
                        } else {
                            //    this.dialog_upload=false;
                            this.showSnackbar("#b71c1c", "File Already Present!!!");
                            //      document.getElementById("AddFile").style.display = "block";
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });


            }
        },
        applyWithCV(item) {
            // if(this.ismarkszero == true){
            //   this.showSnackbar("#b71c1c", this.ismarkszeromsg);
            //   return;
            // }
            // if(this.ispersonaldetailsfreezed == false){
            //   this.showSnackbar("#b71c1c", "Please Freez Personal Details first");
            //   return;
            // }
            // if(this.isexaminationdetailsfreezed == false){
            //   this.showSnackbar("#b71c1c", "Please Freez Examination Details first");
            //   return;
            // }
            // if(this.isacademicsdetailsfreezed == false){
            //   this.showSnackbar("#b71c1c", "Please Freez Academic Details first");
            //   return;
            // }

            // alert("in applyWithCV"+item.questions_available);
            // alert("cv "+item.cvrequired)
            if (item.cvrequired == false && item.questions_available == true) {
                //   alert("question avaiable true cv false")
                this.dataItem = item;
                const formData = {
                    companyOfferingid: this.dataItem.id,
                };
                axios
                    .post("/StudentApplicationTrack/getOfferingQustion", formData)
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.msg == "success") {
                            this.offering_questions_list = res.data.offering_questions_list
                            console.log()

                            this.question_dialog = true
                        } else if (res.data.msg == "blocked_reason") {
                            this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", error); // show snackbar
                        // window.alert(error)
                    })
            }
            else if (item.cvrequired == false && item.questions_available != true) {

                let formData = new FormData();
                formData.append("companyOfferingid", item.id)

                axios
                    .post("/StudentApplicationTrack/applytocompany_mobile", formData)
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.msg == "success") {
                            this.showSnackbar("#4CAF50", "You Have Successfully Applied For Company !");
                            this.init();
                        } else if (res.data.msg == "isblocked") {
                            this.showSnackbar("#b71c1c", "Sorry, You Have Already Accepted Offer From One Company !");
                            this.init();
                        } else if (res.data.msg == "blocked_reason") {
                            this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", error); // show snackbar
                        // window.alert(error)
                    })
            } else if (item.cvrequired == true && item.questions_available == true) {
                //  alert("in cv true questio true")
                this.dataItem = item;
                const formData = {
                    companyOfferingid: this.dataItem.id,
                };
                axios
                    .post("/StudentApplicationTrack/getOfferingQustion", formData)
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.msg == "success") {
                            this.offering_questions_list = res.data.offering_questions_list

                            this.dialog_apply = true;
                        } else if (res.data.msg == "blocked_reason") {
                            this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", error); // show snackbar
                        // window.alert(error)
                    })
            } else if (item.cvrequired == true && item.questions_available != true) {
                //  alert("in cv true questio false")
                this.dataItem = item;
                const formData = {
                    companyOfferingid: this.dataItem.id,
                };
                axios
                    .post("/StudentApplicationTrack/getOfferingQustion", formData)
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.msg == "success") {
                            this.offering_questions_list = res.data.offering_questions_list
                            console.log("size ");
                            this.dialog_apply = true;
                        } else if (res.data.msg == "blocked_reason") {
                            this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", error); // show snackbar
                        // window.alert(error)
                    })
            }
        },

        process(offering_id) {
            // alert("hiii");
            //  this.loading= true;
            const data = {
                companyOffering_id: offering_id,
            };
            console.log("-------------------");
            console.log(offering_id)
            axios
                .post("/TPOCompanyScheduling/checkapplyconditions", data)
                .then((res) => {
                    if (res.data.msg == "200") {


                        console.log("---------------res data----------------------------");
                        console.log(res.data)
                        this.cvList = res.data.cvList;
                        if (res.data.mismatcharray.length > 0) {
                            this.mismatcharraynew = res.data.mismatcharray;
                            this.process_dialog = true;
                            this.c_name = res.data.temp.company_name;
                        }
                        else {
                            this.applyWithCV(res.data.temp)
                        }
                    } else if (res.data.msg == "201") {
                        this.showSnackbar("#b71c1c", res.data.error);
                    } else {
                        this.showSnackbar("#b71c1c", "Something went wrong...");
                    }
                })
                .catch((error) => {
                    window.console.log(error);
                });

        },


        applyWOcvWques(questionItem) {
            //alert(this.dataItem);
            // console.log("applyWOcvWques")
            var count = 0;
            for (var k in this.offering_questions_list) {
                if (this.offering_questions_list[k].question_choice == true) {
                    if (this.offering_questions_list[k].singleanswer == "") {
                        this.showSnackbar("#b71c1c", "Answer All Questions...");
                        return;
                    }
                }
                if (this.offering_questions_list[k].is_attachmentrequired == true) {
                    console.log("file");
                    console.log(this.offering_questions_list[k].answer_file);
                    if (this.offering_questions_list[k].answer_file == null) {
                        this.showSnackbar("#b71c1c", "Attach file as an answer..");
                        return;
                    }
                }
                if (this.offering_questions_list[k].is_attachment_with_description == true) {
                    console.log("file");
                    console.log(this.offering_questions_list[k].answer_file);
                    if (this.offering_questions_list[k].answer_file == null) {
                        this.showSnackbar("#b71c1c", "Attach file as an answer...");
                        return;
                    } if (this.offering_questions_list[k].descrAnswer == "") {
                        this.showSnackbar("#b71c1c", "Answer All Questions..");
                        return;
                    }
                }
                if (this.offering_questions_list[k].question_choice != true && this.offering_questions_list[k].question_multichoice != true && this.offering_questions_list[k].is_attachmentrequired != true) {
                    console.log("hiii");
                    console.log(this.offering_questions_list[k].descrAnswer);
                    if (this.offering_questions_list[k].descrAnswer == "") {
                        this.showSnackbar("#b71c1c", "Answer All Questions..");
                        return;
                    }
                }
                if (this.offering_questions_list[k].question_multichoice == true) {
                    console.log("in multi")
                    console.log(count)
                    for (var a in this.offering_questions_list[k].optionlist) {
                        console.log(this.offering_questions_list[k].optionlist[a].isSelectedOP)
                        if (this.offering_questions_list[k].optionlist[a].isSelectedOP == true) {
                            count++;
                        }
                    }
                    console.log(count);
                    if (count == 0) {
                        this.showSnackbar("#b71c1c", "Answer All Questions...");
                        return;
                    }
                }

            }
            let optionlistnew = []
            let formData = new FormData();
            formData.append("size", this.offering_questions_list.length)
            formData.append("companyOfferingid", this.dataItem.id)
            for (var k in this.offering_questions_list) {
                formData.append("id" + k, this.offering_questions_list[k].id);
                if (this.offering_questions_list[k].question_choice == true) {
                    formData.append("singleanswer" + k, JSON.stringify(this.offering_questions_list[k].singleanswer));
                }
                else if (this.offering_questions_list[k].is_attachmentrequired == true) {
                    formData.append("answer_file" + k, this.offering_questions_list[k].answer_file);
                }
                else if (this.offering_questions_list[k].is_attachment_with_description == true) {
                    formData.append("descrAnswer" + k, this.offering_questions_list[k].descrAnswer);
                    formData.append("answer_file" + k, this.offering_questions_list[k].answer_file);
                } else if (this.offering_questions_list[k].question_multichoice == true) {
                    for (var a in this.offering_questions_list[k].optionlist) {
                        console.log(this.offering_questions_list[k].optionlist[a].isSelectedOP)
                        if (this.offering_questions_list[k].optionlist[a].isSelectedOP == true) {
                            optionlistnew.push(this.offering_questions_list[k].optionlist[a])
                        }
                    }
                    formData.append("optionlist" + k, JSON.stringify(optionlistnew));
                } else if (this.offering_questions_list[k].question_choice != true && this.offering_questions_list[k].question_multichoice != true && this.offering_questions_list[k].is_attachmentrequired != true && this.offering_questions_list[k].is_attachment_with_description != true) {
                    formData.append("descrAnswer" + k, this.offering_questions_list[k].descrAnswer);
                }

            }
            // const data={
            //   questionItem: questionItemarray,
            //   companyOfferingid:this.dataItem.id,
            // };
            axios
                .post("/StudentApplicationTrack/applytocompany_mobile", formData)
                .then((res) => {
                    console.log("res.data");
                    console.log(res.data);
                    if (res.data.msg == "success") {
                        this.question_dialog = false;
                        this.init();
                        this.showSnackbar("#4caf50", "You Have Applied Successfully...");

                    } else if (res.data.msg == "blocked_reason") {
                        this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
                        this.question_dialog = false;
                    } else {
                        this.showSnackbar("#b71c1c", res.data.errormsg);
                        this.question_dialog = false;
                    }
                })
                .catch((error) => {
                    window.console.log(error);
                });
        },

        savenewQuestions(questionItem) {
            console.log(" save new");
            console.log(this.dataItem1.id);
            var count = 0;
            for (var k in questionItem) {
                console.log("in offering ")
                if (questionItem[k].question_choice == true) {
                    if (questionItem[k].singleanswer == "") {
                        this.showSnackbar("#b71c1c", "Answer All Questions...");
                        return;
                    }
                }
                if (questionItem[k].is_attachmentrequired == true) {
                    if (questionItem[k].answer_file == null) {
                        this.showSnackbar("#b71c1c", "Attach file as an answer...");
                        return;
                    }
                }
                if (questionItem[k].is_attachment_with_description == true) {
                    console.log("file");
                    console.log(questionItem[k].answer_file);
                    if (questionItem[k].answer_file == null) {
                        this.showSnackbar("#b71c1c", "Attach file as an answer ...");
                        return;
                    } if (questionItem[k].descrAnswer == "") {
                        this.showSnackbar("#b71c1c", "Answer All Questions..");
                        return;
                    }
                }
                if (questionItem[k].question_choice != true && questionItem[k].question_multichoice != true && questionItem[k].is_attachmentrequired != true) {
                    // console.log("hiii")
                    if (questionItem[k].descrAnswer == "") {
                        this.showSnackbar("#b71c1c", "Answer All Questions...");
                        return;
                    }
                }
                if (questionItem[k].question_multichoice == true) {
                    console.log("in multi")
                    console.log(count)
                    for (var a in questionItem[k].optionlist) {
                        console.log(questionItem[k].optionlist[a].isSelectedOP)
                        if (questionItem[k].optionlist[a].isSelectedOP == true) {
                            count++;
                        }
                    }
                    console.log(count);
                    if (count == 0) {
                        this.showSnackbar("#b71c1c", "Answer All Questions...");
                        return;
                    }
                }
            }
            // const data={
            //   questionItem: questionItem,
            // };
            let optionlistnew = []
            let formData = new FormData();
            formData.append("size", questionItem.length)
            formData.append("companyOfferingid", this.dataItem1.id)
            for (var k in questionItem) {
                formData.append("id" + k, questionItem[k].id);
                if (questionItem[k].question_choice == true) {
                    formData.append("singleanswer" + k, JSON.stringify(questionItem[k].singleanswer));
                }
                else if (questionItem[k].is_attachmentrequired == true) {
                    formData.append("answer_file" + k, questionItem[k].answer_file);
                }
                else if (questionItem[k].is_attachment_with_description == true) {
                    formData.append("descrAnswer" + k, questionItem[k].descrAnswer);
                    formData.append("answer_file" + k, questionItem[k].answer_file);
                } else if (questionItem[k].question_multichoice == true) {

                    for (var a in questionItem[k].optionlist) {
                        console.log(questionItem[k].optionlist[a].isSelectedOP)
                        if (questionItem[k].optionlist[a].isSelectedOP == true) {
                            optionlistnew.push(questionItem[k].optionlist[a])
                        }
                    }
                    formData.append("optionlist" + k, JSON.stringify(optionlistnew));
                } else if (questionItem[k].question_choice != true && questionItem[k].question_multichoice != true && questionItem[k].is_attachmentrequired != true && questionItem[k].is_attachment_with_description != true) {
                    formData.append("descrAnswer" + k, questionItem[k].descrAnswer);
                }
                // formData.append("question"+k, this.offering_questions_list[k].question);
            }
            axios
                .post("/StudentApplicationTrack/savenewQuestions", formData)
                .then((res) => {
                    console.log("res.data");
                    console.log(res.data);
                    if (res.data.msg == "success") {
                        this.newquestion_dialog = false;
                        this.init();
                        this.showSnackbar("#4caf50", "Answers Saved Successfully...");
                    } else {
                        this.showSnackbar("#b71c1c", res.data.errormsg);
                        this.question_dialog = false;
                    }
                })
                .catch((error) => {
                    window.console.log(error);
                });
        },

        viewRoundinfo(item) {
            const formData = {
                companyOfferingLearnerid: item,
            };
            axios
                .post("/StudentApplicationTrack/viewRoundInfo", formData)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.msg == "200") {
                        this.company = res.data.company_list;
                        this.studentround_list = res.data.studentround_list;
                        this.finalselected = res.data.finalselected
                        this.dialog1 = true;
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                })
                .finally(() => { });
        },

        close() {
            this.dialog_apply = false;
            this.process_dialog = false;
            setTimeout(() => {
                this.dataItem = null;
                this.cvFile = null;
                // window.alert(`DataItemNow=${ this.dataItem } && Cv=${this.cvFile}`)
            }, 300);
        },
        closequestion() {
            this.question_dialog = false;
            setTimeout(() => {
                this.dataItem = null;
                // window.alert(`DataItemNow=${ this.dataItem } && Cv=${this.cvFile}`)
            }, 300);
        },

        finddetails(item) {
            // alert(item.id)
            this.$store.commit("setcompany_id", item.id);
            //  this.$router.push("/company-info");
            this.$router.push({ name: "company-info" });
        },
        applyCompany(item, index) {
            console.log("show qestion applyCompany");
            console.log(this.offering_questions_list);
            var count = 0;
            for (var k in this.offering_questions_list) {
                console.log("in offering ")
                if (this.offering_questions_list[k].question_choice == true) {
                    if (this.offering_questions_list[k].singleanswer == "") {
                        this.showSnackbar("#b71c1c", "Answer All Questions...");
                        return;
                    }
                }
                if (this.offering_questions_list[k].question_choice != true && this.offering_questions_list[k].question_multichoice != true && this.offering_questions_list[k].is_attachmentrequired != true) {
                    console.log("hiii");
                    console.log(this.offering_questions_list[k].descrAnswer);
                    if (this.offering_questions_list[k].descrAnswer == "") {
                        this.showSnackbar("#b71c1c", "Answer All Questions...");
                        return;
                    }
                }
                if (this.offering_questions_list[k].is_attachmentrequired == true) {
                    if (this.offering_questions_list[k].answer_file == null) {
                        this.showSnackbar("#b71c1c", "Attach file as an answer...");
                        return;
                    }
                }
                if (this.offering_questions_list[k].is_attachment_with_description == true) {
                    console.log("file");
                    console.log(this.offering_questions_list[k].answer_file);
                    if (this.offering_questions_list[k].answer_file == null) {
                        this.showSnackbar("#b71c1c", "Attach file as an answer...");
                        return;
                    } if (this.offering_questions_list[k].descrAnswer == "") {
                        this.showSnackbar("#b71c1c", "Answer All Questions..");
                        return;
                    }
                }
                if (this.offering_questions_list[k].question_multichoice == true) {
                    console.log("in multi")
                    console.log(count)
                    for (var a in this.offering_questions_list[k].optionlist) {
                        console.log(this.offering_questions_list[k].optionlist[a].isSelectedOP)
                        if (this.offering_questions_list[k].optionlist[a].isSelectedOP == true) {
                            count++;
                        }
                    }
                    console.log(count);
                    if (count == 0) {
                        this.showSnackbar("#b71c1c", "Answer All Questions...");
                        return;
                    }
                }

            }
            //conl.log("h")
            if (this.cvFile == null) {
                this.showSnackbar("#b71c1c", "Please Select A File...");
            }
            else {
                this.dialog_upload = true;
                // const formData = {
                //   companyOfferingid: this.dataItem.id,
                //   cvFile:this.cvFile,
                //   questionItem: this.offering_questions_list,

                // };

                let formData = new FormData();
                formData.append("size", this.offering_questions_list.length)
                formData.append("cvFile", this.cvFile)
                formData.append("companyOfferingid", this.dataItem.id)
                for (var k in this.offering_questions_list) {
                    let optionlistnew = []
                    formData.append("id" + k, this.offering_questions_list[k].id);
                    if (this.offering_questions_list[k].question_choice == true) {
                        formData.append("singleanswer" + k, JSON.stringify(this.offering_questions_list[k].singleanswer));
                    }
                    else if (this.offering_questions_list[k].is_attachmentrequired == true) {
                        formData.append("answer_file" + k, this.offering_questions_list[k].answer_file);
                    }
                    else if (this.offering_questions_list[k].is_attachment_with_description == true) {
                        formData.append("descrAnswer" + k, this.offering_questions_list[k].descrAnswer);
                        formData.append("answer_file" + k, this.offering_questions_list[k].answer_file);
                    } else if (this.offering_questions_list[k].question_multichoice == true) {
                        for (var a in this.offering_questions_list[k].optionlist) {
                            if (this.offering_questions_list[k].optionlist[a].isSelectedOP == true) {
                                optionlistnew.push(this.offering_questions_list[k].optionlist[a])
                            }
                        }
                        formData.append("optionlist" + k, JSON.stringify(optionlistnew));
                    } else if (this.offering_questions_list[k].question_choice != true && this.offering_questions_list[k].question_multichoice != true && this.offering_questions_list[k].is_attachmentrequired != true && this.offering_questions_list[k].is_attachment_with_description != true) {
                        formData.append("descrAnswer" + k, this.offering_questions_list[k].descrAnswer);
                    }
                    // formData.append("question"+k, this.offering_questions_list[k].question);

                }
                axios
                    .post("/StudentApplicationTrack/applytocompany_mobile", formData)
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.msg == "success") {

                            this.showSnackbar("#4CAF50", "You Have Successfully Applied For Company !");
                            this.company_list.splice(index, 1)
                            this.dialog_upload = false;
                            this.dialog_apply = false;

                            this.close();

                        } else if (res.data.msg == "isblocked") {
                            this.showSnackbar("#b71c1c", "You Have Already Accepted Offer From One Company !");
                            this.dialog_upload = false;
                            this.dialog_apply = false;
                            this.close();
                            this.init();
                        } else if (res.data.msg == "blocked_reason") {
                            this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
                            this.dialog_upload = false;
                            this.close();
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
                            this.dialog_upload = false;
                            this.close();
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", error); // show snackbar
                        // window.alert(error)
                        this.dialog_upload = false;
                        this.close();
                    })
            }
        },

        newQuestionDialog(item) {
            this.dataItem1 = item;
            const formData = {
                companyOfferingid: this.dataItem1.id,
            };
            axios
                .post("/StudentApplicationTrack/getNewOfferingQustion", formData)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.msg == "success") {
                        this.offeringnew_questions_list = res.data.offeringnew_questions_list

                        this.newquestion_dialog = true;
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", error); // show snackbar
                    // window.alert(error)
                })
        },
        applyCompany1() {
            // alrt("in applyCompany1")
            const formData = {
                companyOfferingid: this.dataItem,
            };
            // window.alert(`FormData ${JSON.stringify(formData)}`)
            axios
                .post("/StudentApplicationTrack/applyForCompany", formData)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "You Have Successfully Applied For Company !");
                        this.dialog_upload = false;
                        this.dialog_apply = false;
                        this.close();
                        this.init();
                    } else if (res.data.msg == "blocked_reason") {
                        this.showSnackbar("#b71c1c", "You are blocked because " + res.data.errormsg);// show snackbar
                        this.dialog_upload = false;
                        this.close();
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
                        this.dialog_upload = false;
                        this.close();
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", error); // show snackbar
                    // window.alert(error)
                    this.dialog_upload = false;
                    this.close();
                })
        },

    },
};
</script>